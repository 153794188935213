import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet';

// 定義每個 name 對應的 og 資訊
const OG_CONFIG = {
  '123': {
    title: '網頁 123 標題',
    description: '網頁 123 的描述',
    image: 'https://your-site.com/image-for-123.jpg',
    url: 'https://your-site.com/page?name=123'
  },
  '456': {
    title: '網頁 456 標題',
    description: '網頁 456 的描述',
    image: 'https://your-site.com/image-for-456.jpg',
    url: 'https://your-site.com/page?name=456'
  },
  '789': {
    title: '網頁 789 標題',
    description: '網頁 789 的描述',
    image: 'https://your-site.com/image-for-789.jpg',
    url: 'https://your-site.com/page?name=789'
  }
};

function DynamicPage() {
  // 使用 state 存儲 name
  const [name, setName] = useState('123');

  useEffect(() => {
    // 僅在瀏覽器環境中執行
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const paramName = urlParams.get('name') || '123';
      setName(paramName);
    }
  }, []);

  // 根據 name 取得對應的 og 資訊
  const ogInfo = OG_CONFIG[name] || OG_CONFIG['123'];

  return (
    <>
      <Helmet>
        {/* 動態設置 Open Graph meta tags */}
        <meta property="og:title" content={ogInfo.title} />
        <meta property="og:description" content={ogInfo.description} />
        <meta property="og:image" content={ogInfo.image} />
        <meta property="og:url" content={ogInfo.url} />
        <meta property="og:type" content="website" />
      </Helmet>

      {/* 網頁內容 */}
      <div>網頁 {name} 的內容</div>
    </>
  );
}

export default DynamicPage;